import React from 'react';
import SEO from '../../components/seo';
import CategoryPage from '../../components/desktop/Categories/CategoryPage';
import FamilyEN from '../../components/desktop/Family/FamilyEN';

const Artist = () => {
  return (
    <>
      <SEO title={'Family'} />
      <FamilyEN />
    </>
  );
};

export default Artist;
