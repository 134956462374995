import React from 'react';
import {
  ColorWrapper,
  Container,
  GalleryColumnTwo,
  ImageMove,
  PageContainer,
  PhotoSign,
  TextSpaceMargin,
} from '../AuthorPage/styles';
import {GalleryAsyncGrid, PhotoWithSign} from './styles';
import {SectionText, SectionTitle} from '../../atoms';
import img0 from './images/00.png';
import img1 from './images/1.jpg';
import img2 from './images/2.jpg';
import img3 from './images/3.jpg';
import img4 from './images/4.jpg';
import img5 from './images/5.jpg';
import img6 from './images/6.jpg';
import img7 from './images/7.jpg';
import img8 from './images/8.jpg';
import img9 from './images/9.jpg';
import img10 from './images/10.jpg';
import img11 from './images/11.jpg';
import img12 from './images/12.jpg';
import img13 from './images/13.jpg';
import img14 from './images/14.jpg';
import img15 from './images/15.jpg';
import img16 from './images/16.jpg';
import img17 from './images/17.jpg';
import img18 from './images/18.jpg';
import img19 from './images/19.jpg';
import img20 from './images/20.jpg';
import img21 from './images/21.jpg';
import img22 from './images/22.png';
import img23 from './images/23.png';
import img24 from './images/24.png';
import img25 from './images/25.jpg';
import img26 from './images/26.png';
import img27 from './images/27.png';
import img28 from './images/28.png';
import img29 from './images/29.jpg';
import img30 from './images/30.jpg';
import img31 from './images/31.jpg';
import img32 from './images/32.jpg';
import img33 from './images/33.jpg';
import Gallery from '../Gallery/Gallery';
import PlayerGrey from '../../atoms/PlayerGrey/PlayerGrey';

const Family = () => {
  return (
    <PageContainer backgroundColor={'#e09087'} style={{paddingBottom: 0}}>
      <Container>
        <ImageMove src={img0} />
        <TextSpaceMargin>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}>
            <SectionTitle color={'#525252'}>FAMILY</SectionTitle>
            <PlayerGrey src={require('../../../audio/RODZINA.mp3')} />
          </div>
          <SectionText color={'#525252'} style={{paddingTop: '1.5rem'}}>
            &emsp;&emsp;The family was very important to Jan Szczepkowski. His
            attachment is especially visible in the relationship with his mother
            Józefa Szczepkowska from the Kuczyńscy (1840-1891, daughter of Jan
            and Maria from the Katańscy). The artist's mother died in 1891, when
            the future sculptor was only 13 years old. Szczepkowski mentioned
            her and her family at the very beginning of his memories contained
            in a book “Convex and concave”. He mentions that he truly adored
            her. She was brought up in Lwów during the January Uprising in 1863.
            As a 13-year-old girl, she was working for an insurgent organization
            that hid and looked after the insurgents. Jan Kuczyński, artist’s
            grandfather, was a famous lutenist at the time, as well as an
            acoustician educated in Italy. He owned the village of Hołosko near
            Lwów and several tenement houses in the city, so he was quite
            wealthy.
            <br />
            <br />
            &emsp;&emsp;Szczepkowski owes his prematurely deceased mother whom
            he has become. The artist's father wished Szczepkowski to receive a
            strictly technical education, but at the urging of his mother he
            allowed him to learn to draw and sculpture. The artist's departure
            to the School of Wood Industry in Zakopane is associated with
            another difficult life period of the artist, which he remembered
            painfully at the end of his life in the memoirs “Convex and Concave”
            compiled by Katarzyna Chrudzimska-Uhera. He mentions that leaving
            his family was an extremely difficult experience, especially saying
            goodbye to his mother, as if he had known he was not going to see
            her ever again.
            <br />
            <br />
            &emsp;&emsp;Jan Szczepkowski's father, Alojzy Szczepkowski
            (1839-1916, son of Józef and Maria from the Ruczka family) was an
            official of the C.K. Iron Railways. Alojzy Szczepkowski, had two
            more sons with Józefa: Kazimierz (1874-1945) and Michał (1885-1976).
            <br />
            <br />
            &emsp;&emsp;In the family of Jan Szczepkowski, the artistic
            abilities appeared very frequently. It can be very well observed in
            the family branch of Michał Szczepkowski. After marrying Helena
            Recht (1893-1955), he had two children: Irena (1921-1971) and
            Andrzej (1923-1997). Andrzej Szczepkowski was a famous Polish actor,
            like his daughter Joanna Szczepkowska (born 1953). She had two
            daughters with Mirosław Konarowski: Maria and Hanna who also became
            actresses. In addition, Joanna’s mother Roma Parandowska (1927-2007)
            was a daughter of the author of Mythology and twice nominated for
            the Nobel Prize of Literature Jan Parandowski (1895-1978), which
            means that Andrzej was his son-in-law.
          </SectionText>
        </TextSpaceMargin>
      </Container>
      <ColorWrapper
        bgColor={'#525252'}
        style={{marginTop: '3rem', paddingBottom: '2rem'}}>
        <Container>
          <Gallery
            maxWidth={60}
            columnsNumber={2}
            isImgFit
            hoverColor="rgba(224, 144, 135, 0.4)"
            colorSign="#fbfbfb"
            images={[
              {src: img1, sign: 'Maria i Jan w 1917 r.'},
              {
                src: img2,
                sign:
                  'Jan Szczepkowski z żoną Marią przed lotem do Gdyni, 1937',
              },
            ]}
          />
          <SectionText color={'#fbfbfb'}>
            &emsp;&emsp;Jan Szczepkowski got married on 18 May 1913 to Maria
            Morozowicz (1885-1968). The wedding took place in the Bernardine
            church in Cracow. The witnesses at the wedding were the sculptor
            Xawery Dunikowski and the lawyer Albin Chęcińśki. Maria Morozowicz
            was an actress and writer. After graduating from N. Porazińska’s
            finishing school in Warsaw, she became an auditor at the
            Jagiellonian University in Cracow. It was there that she met and
            later married Jan. Before meeting him, she was growing as a
            successful actress. From 1902 she performed in the group of the
            Stage Lovers in the Elizeum hall at Karowa Street in Warsaw. She
            played in the Summer Theater, the Novelties Theater and the Polish
            Theater in Vilnius. Back then, she was active in the artistic and
            literary group “Banda” and debuted with her poetry in the publishing
            house “Żurawce”. She left the stage in 1913, when she devoted
            herself to a literary career after her marriage. She made one of the
            few exceptions when she returned to the stage of the Summer Theater
            in Milanówek with the premiere of the play New Woman (1929), which
            she directed and where she played the main role.
            <br />
            <br />
            &emsp;&emsp;In 1914, her farce “Tutor” won the second prize in the
            competition organized by the Modern Theater in Warsaw. When she
            moved to Milanówek after World War I, in the villa Waleria she wrote
            her most famous stage works. She was also the author of various
            movie scripts. In Warsaw in 1968, her memories contained in a book
            “Z lotu ptaka” got published.
          </SectionText>
        </Container>
      </ColorWrapper>
      <ColorWrapper bgColor={'#e09087'} style={{paddingBottom: '2rem'}}>
        <Container>
          <Gallery
            columnsNumber={2}
            maxWidth="60"
            isImgFit
            hoverColor="rgba(224, 144, 135, 0.4)"
            colorSign="#525252"
            images={[
              {
                src: img3,
                sign: 'Waleria wraz z&nbsp;córką Marią w&nbsp;ogrodzie',
              },
              {
                src: img10,
                sign:
                  'Waleria w oczekiwaniu na pociąg na przystanku kolejowym w Milanówku',
              },
            ]}
          />
          <SectionText color={'#525252'}>
            &emsp;&emsp;Maria was born in an artistic family. Her parents
            Waleria from the Kotowicz family (1862-1931, the daughter of Ksawery
            and Róża) and Rufin Morozowicz (1848-1931, son of Rufin and
            Katarzyna) all became stage artists. They got married in 1885, which
            was Rufins second marriage. From his first relationship with actress
            Rozalia Kozakowska (1851-1883) he had two children, who also became
            actors: Karolina (1867-1952) and Leopold (1876-1945).
            <br />
            <br />
            &emsp;&emsp;Waleria Morozowicz, from the Kotowicz family, was a
            mother-in-law of Jan Szczepkowski and an artist gifted with the
            highest female vocals: soprano coloratura. After marrying Rufin, she
            indulges herself in housekeeping and just “kept her husband’s side”
            as it was expected of women back then. When one of her children
            Jerzy had died from scarlet fever, she fell ill with eczema. Shortly
            following her recovery she took up an actual job in husband’s
            company. Despite his many ideas Rufin didn’t have any luck in
            business. One of his start-ups which was winemaking caused him to
            fall into debt. Only Waleria’s enterprise and strong character
            helped them build their fortune accumulated in their property in
            Błoń and two houses in Milanówek with vast gardens. She convinced
            her husband to open a perfume company and a shop. Of course, she was
            the head of the whole project, though her husband was the one
            branding it, thanks to his chemist profession.
            <Gallery
              columnsNumber={2}
              isImgFit
              maxWidth="60"
              hoverColor="rgba(224, 144, 135, 0.4)"
              colorSign="#525252"
              images={[
                {src: img4, sign: 'Rufin Morozowicz'},
                {
                  src: img22,
                  sign: 'Rufin Morozowicz w&nbsp;scenicznej kreacji',
                },
              ]}
            />
            &emsp;&emsp;Father-in-law of Szczepkowski Rufin Morozowicz was a
            truly fascinating character. After graduating from Barszczewski’s,
            school he got into the chemical department of Warsaw General School.
            At some point, he left Warsaw to join an acting team on a trip to
            Płock, where he began his acting career. Later on, he stayed in
            Cracow and played comedy roles and sang in operas. He was also the
            director of his own theater, Morozowicz-Krotke, which he managed
            after his return to Warsaw. He mainly focused on comedies, but later
            on he gradually switched to Warsaw operetta as an actor and
            director. In 1890, he was offered guest appearances at the Imperial
            Theaters in St. Petersburg. Despite the promise of high earnings, he
            did not take the offer. There was also an incident considering his
            resignation from the position of the director of the operetta. When
            he got offended by actress Adolfina Zimajer, he submitted his
            resignation. The actress accused him of preferring production of
            wine to working as a director. The accusation was true, because
            after a visit to Vienna, where he was at the world premiere of
            Sydney Jones' Geisha operetta he was directing, he came up with the
            idea of producing his own wine from grapes imported from Crimea.
            However, the idea turned out to be a flop, leaving him in the debt
            of 100,000 rubles which he was repaying almost to the end of his
            life. Not discouraged by the failure, a few years later in the attic
            of the building at Czerniakowska Street in Warsaw he began to paint
            theater decorations for provincial theaters.
            <br />
            <br />
            &emsp;&emsp;He finally succeeded in opening a chemical laboratory
            and a store in partnership with Brandl. Thanks to Rufin’s wife
            Waleria, the enterprise started to bring income. It was enough for
            him to start the restoration process of the Waleria Villa. He was an
            architectural supervisor and an investor. The building was erected
            in 1910 and became a safe haven for the Morozowicz family. It
            survived in its original two-story form until 1915. The last floor
            burned down at that time due to the explosion of a shrapnel. The
            reconstruction in 1920 was again undertaken by Rufin Morozowicz, but
            this time building a one-story construction.
            <br />
            <br />
            &emsp;&emsp;Rufin Morozowicz died on 14 April 1931 in Milanówek. His
            wife Waleria died on 21 August 1931 also in Milanówek. They got
            buried together in the family tomb on the Powązki Cemetery. Their
            house was inherited by their daughter Maria, and after her death,
            “Waleria'' became the property of her daughter Hanna Mickiewicz from
            the family of Szczepkowscy (1915-2006).
          </SectionText>
        </Container>
      </ColorWrapper>
      <ColorWrapper bgColor={'#525252'} style={{paddingBottom: '2rem'}}>
        <Container>
          <SectionText color={'#fbfbfb'}>
            <Gallery
              columnsNumber={2}
              isImgFit
              maxWidth="60"
              hoverColor="rgba(224, 144, 135, 0.4)"
              colorSign="#fbfbfb"
              images={[
                {src: img5, sign: 'Maria wraz z&nbsp;córeczką Hanią'},
                {
                  src: img6,
                  sign: 'Hanna i&nbsp;Adam Mickiewiczowie',
                },
              ]}
            />
            &emsp;&emsp;Hanna was born in Cracow, when Jan Szczepkowski was
            transferred to the reserve because he got wounded. After her Matura
            exam (Finals), she joined the Urban School of Decorative Arts and
            Painting conducted by her father. She was already talented in
            ceramics. During the occupation period, she studied medicine for two
            years.
            <br />
            <br />
            &emsp;&emsp;After the outbreak of the second world war she joined
            the underground and began her work as a liaison for special tasks.
            She was under the command of Major Marian Drobik alias “Woodpecker”
            who was a deputy and later the chief of the Second Main Command
            Troop of the Union of Armed Struggle (later called Home Army). In
            the late 1942 she was moved to work at the Industrial Studies Office
            of the Second Main Command of Home Army which her husband Adam
            Rymwid Mickiewicz (1905-1986) alias “Konrad” has been the chief of
            since October 1942.
            <br />
            <br />
            &emsp;&emsp;Adam Rymwid Mickiewicz's distant ancestor was the Polish
            national bard Adam Mickiewicz (1798-1855). Adam Rymwid's
            great-grandfather, Aleksander Julian Rymwid-Mickiewicz (1801-1871)
            was the brother of the national poet of Poland, Lithuania and
            Belarus. An interesting fact is that the first husband of the
            grandmother on the side of Adam Rymwid Mickiewicz's father, Antonina
            from the Kościuszko family(1830-1906), was the dictator of the
            January Uprising, Romuald Traugutt. The name Kościuszko is also not
            a coincidence, as Antonina was the granddaughter of Tadeusz
            Kościuszko's brother.
            <br />
            <br />
            &emsp;&emsp;Adam Rymwid Mickiewicz and his Industrial Studies Office
            was preparing monthly reports based upon information collected from
            undercover agents from all over the Europe. Subsequently, the
            reports were sent to London, where they reached the Command-in-chief
            Staff. They mainly concerned Nazi’s (German Reich) arms industry.
            Among them, we can find one of the most important achievements of
            Polish intelligence agency: discovering the production of German V1
            and V2 type missiles. Thanks to that information allies were able to
            bomb the Experimental Center in Peenemuende in 1943 and suppress
            Hitler’s plan on destroying England.
            <br />
            <br />
            &emsp;&emsp;After the outbreak of the Warsaw Uprising, Hanna
            Mickiewiczowa joined sanitary service.
            <br />
            <br />
            &emsp;&emsp;Years following the end of World War II were a difficult
            period in the couple’s life. On the night of November 29-30 1950,
            Adam was arrested and was not let free until May 1953. The
            prosecutor who accused Mickiewicz was Helena Wolińska. In 1998, the
            Ministry of Justice of the Republic of Poland sent a request to the
            British authorities for the extradition of Wolińska, accusing her of
            illegal deprivation of liberty in the years 1950–1953 of 24 soldiers
            of the Home Army, including Gen. Brig. August Emil Fieldorf alias
            “Nil”. On November 21, 1950, she issued a detention order for
            Fieldorf and supervised an investigation against him, which resulted
            in the execution of the “Nil”.
            <br />
            <br />
            &emsp;&emsp;During Adam Mickiewicz’s stay in prison, he fell ill and
            wasn’t getting any better even after getting released. Hanna
            Mickiewicz was carrying microfilms containing reports in a specially
            sewn belt during the search of the Security Service and decided to
            reveal them in November 1991. For many years Hanna has participated
            in Home Army’s operations and was a long-time member of the Social
            Committee of the Warsaw District Union. A few weeks before her
            death, she was awarded the Officer's Cross of the Order of Polonia
            Restituta. She received many more commendations, among them the
            Bronze Cross of Merit with Swords or the Home Army Cross. For many
            years, she cooperated with the Milanówek Enthusiasts Society in
            promoting knowledge about the difficult town’s history of Nazi
            occupation, Warsaw Uprising and Industrial Studies Office activity.
            <br />
            <br />
            <br />
            &emsp;&emsp;Since 1978, she has been running a private museum of Jan
            Szczepkowski in the Waleria Villa. In 2007 the building and its
            impressive collection of Szczepkowski's artwork was purchased by
            town’s authorities. The rest of his art pieces remain in the
            foundation managed by Ewa Mickiewicz (artist’s granddaughter), which
            was established to protect and promote the artistic work of Jan
            Szczepkowski and the literary work of Maria Szczepkowska.
          </SectionText>
        </Container>
        <Container style={{marginTop: '2rem'}}>
          <p
            style={{
              fontFamily: 'LATOREGULAR',
              fontSize: '10px',
              lineHeight: 1.2,
              textAlign: 'justify',
              color: '#ffffff',
            }}>
            Information sources:
            <br />
            <br />
            Bartoszek Monika, Jan Szczepkowski, Centrum Rzeźby Polskiej w
            Orońsku, Orońsko, 2014
            <br />
            <br />
            Chrudzimska – Uhera Katarzyna, JAN SZCZEPKOWSKI, ŻYCIE I TWÓRCZOŚĆ,
            Urząd Miejski w Milanówku, 2008 r.
            <br />
            <br />
            Chrudzimska-Uhera Katarzyna, Małżeństwo z rozsądku? : czyli o
            artystycznych dylematach i kompromisach w czasach władzy ludowej, w
            oparciu o działalność społeczną, wypowiedzi i projekty rzeźbiarskie
            Jana Szczepkowskiego z lat 1945-1964, Saeculum Christianum : pismo
            historyczne
            <br />
            <br />
            Chrudzimska-Uhera Katarzyna, O rzeźbiarzu (nieco) zapomnianym,
            Niepodległość i Pamięć 17/1 (31), 257-271, 2010
            <br />
            <br />
            Chrudzimska-Uhera Katarzyna, Prof. Jan Szczepkowski i jego dzieło
            (1878-1964), Milanówek Miejsce Magiczne pod red. Andrzeja Pettyna,
            Towarzystwo Miłośników Milanówka, 2005
            <br />
            <br />
            Koszuta Mariusz, Spacer z Walerią po letnisku Milanówek, Wydawnictwo
            Impress, Warszawa 2010 r.
            <br />
            <br />
            Morozowicz – Szczepkowska Maria, Z lotu ptaka, Państwowy Instytut
            Wydawniczy, Warszawa, 1968
            <br />
            <br />
            Szczepkowski Jan, Wypukłe i wklęsłe. Wspomnienia, Wstęp, opracowanie
            i komentarz Katarzyna Chrudzimska - Uhera, Urząd Miasta Milanówka,
            Milanówek, 2010
          </p>
          <a
            style={{
              fontFamily: 'LATOREGULAR',
              fontSize: '10px',
              lineHeight: 1.2,
              textAlign: 'justify',
              color: '#ffffff',
              marginTop: '10px',
            }}
            href={
              'https://www.1944.pl/archiwum-historii-mowionej/hanna-barbara-szczepkowska-mickiewicz,788.html'
            }>
            https://www.1944.pl/archiwum-historii-mowionej/hanna-barbara-szczepkowska-mickiewicz,788.html
          </a>
          <br />

          <a
            style={{
              fontFamily: 'LATOREGULAR',
              fontSize: '10px',
              lineHeight: 1.2,
              textAlign: 'justify',
              color: '#ffffff',
            }}
            href={
              'https://www.encyklopediateatru.pl/autorzy/788/maria-morozowicz-szczepkowska'
            }>
            https://www.encyklopediateatru.pl/autorzy/788/maria-morozowicz-szczepkowska
          </a>
          <br />
          <a
            style={{
              fontFamily: 'LATOREGULAR',
              fontSize: '10px',
              lineHeight: 1.2,
              textAlign: 'justify',
              color: '#ffffff',
            }}
            href={'http://www.tmm.net.pl/print,news,752.html#_ftn2'}>
            http://www.tmm.net.pl/print,news,752.html#_ftn2
          </a>
        </Container>
      </ColorWrapper>
      <ColorWrapper bgColor={'#e09087'}>
        <Container>
          <Gallery
            columnsNumber={4}
            isImgFit
            hoverColor="rgba(224, 144, 135, 0.4)"
            style={{gridGap: '3rem 1rem'}}
            colorSign="#525252"
            images={[
              {
                src: img7,
                sign: 'Chwila odpoczynku. Waleria i Rufin razem z córką Marią',
              },
              {
                src: img8,
                sign: 'Jan Szczepkowski z żoną Marią oraz jej matką Walerią',
              },
              {
                src: img9,
                sign:
                  'Rodzinna scena. Małżeństwo Szczepkowskich i Waleria Morozowicz.',
              },
              {
                src: img11,
                sign: 'Waleria Morozowicz na tle willi Ewarystówka',
              },
              {
                src: img12,
                sign: 'Maria Morozowicz w trakcie teatralnej kreacji',
              },
              {
                src: img13,
                sign:
                  'Maria Morozowicz (siedzi pierwsza z lewej) w towarzystwie środowiska artystycznego WIlna przełomu XIX i XX wieku.',
              },
              {
                src: img14,
                sign: 'Maria Morozowicz',
              },
              {
                src: img15,
                sign:
                  'Jan Szczepkowski na tle Świątynia Hery w Agrigento na Sycylii, 1937r.',
              },
              {
                src: img16,
                sign:
                  "Maria Szczepkowska na wakacjach we Włoszech w 1937 r. Na odwrocie opis 'Rzym Colloseum'",
              },
              {
                src: img17,
                sign:
                  'Maria Szczepkowska na tle panoramy Taorminy, Sycylia, 1937 r.',
              },
              {
                src: img18,
                sign:
                  'Wakacje na Sycyli w 1937 r. Z tyłu zdjęcia opis Nasz niedoszły domek w Taorminie na Sycylii',
              },
              {
                src: img19,
                sign:
                  'Maria Szczepkowska na tle Świątyni Zgody w Agrigento na Sycylii, 1937 r.',
              },
              {
                src: img20,
                sign:
                  'Jubileusz pięćdziesięciolecia pracy scenicznej Rufina Morozowicza. Teatr Nowości, 12 marca 1925 r.',
              },
              {
                src: img21,
                sign: 'Rufin Morozowicz w otoczeniu ogrodu',
              },

              {
                src: img23,
                sign: 'Rufin Morozowicz w scenicznej kreacji',
              },
              {
                src: img24,
                sign: 'Rufin Morozowicz w scenicznej kreacji',
              },
              {
                src: img28,
                sign:
                  'Zaświadczenie o przyznaniu Krzyża Srebrnego Orderu Virtuti Militari dla Adama Mickiewcza',
              },
              {
                src: img26,
                sign: 'Róża Kotowicz, matka Walerii',
              },
              {
                src: img27,
                sign:
                  'Jerzy Morozowicz, zmarły przedwcześnie syn Rufina i Walerii',
              },
              {
                src: img25,
                sign:
                  'Rufin Morozowicz w trakcie spaceru po Letnisku Milanówek',
              },
              {
                src: img29,
                sign: 'Laboratorium firmy Perfection w podwórzu po lewo',
              },
              {
                src: img30,
                sign: 'Maria Morozowiczówna po skończeniu pensji',
              },
              {
                src: img31,
                sign:
                  'Nad morzem w Jastarnii. Od lewej Maria Skoczylas, Monika Żeromska, Hanna Szczepkowska',
              },
              {
                src: img32,
                sign:
                  'Rufin Morozowicz jako Menelaus w Pięnej Helenie Offenbacha',
              },
            ]}
          />
        </Container>
      </ColorWrapper>
    </PageContainer>
  );
};

export default Family;
