import styled, {keyframes} from 'styled-components';

export const PageContainer = styled.div`
  width: 100%;
  background-color: ${({backgroundColor}) => backgroundColor};
  padding: 5rem 0;
  margin-top: -12px;
`;

export const Container = styled.div`
  width: 100%;
  max-width: 50rem;
  margin: 0 auto;

  @media (max-width: 1024px) {
    padding: 0 40px;
  }
`;

export const GalleryItem = styled.a`
  display: block;
  text-decoration: none;
  width: 100%;
  height: 100%;
  position: relative;
  cursor: pointer;
  box-shadow: 0 0 37px 7px rgba(8, 9, 8, 0.11);

  &:hover {
    div {
      opacity: 1;
    }
  }
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
`;

export const Darken = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.4);
  transition: 0.3s;
  opacity: 0;
`;

export const Title = styled.h4`
  font-family: LATOBLACK, sans-serif;
  font-size: 0.7rem;
  text-transform: uppercase;
  letter-spacing: 0.67px;
  color: #ffffff;
  font-weight: 400;
  text-align: center;
`;

export const GalleryItemMobile = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
`;

export const TitleMobile = styled.h4`
  font-family: 'LATO', sans-serif;
  font-size: 0.7rem;
  text-transform: uppercase;
  letter-spacing: 0.67px;
  color: #ffffff;
  font-weight: 500;
  text-align: center;
  margin-top: 0.4rem;
`;

export const ColorWrapper = styled.div`
  background-color: ${({bgColor}) => bgColor};
  padding: 1rem 0;
  position: relative;
`;

export const GalleryColumnTwo = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
`;

export const PhotoWithSign = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2rem 0;

  div {
    position: relative;
    &:hover {
      &::after {
        content: '';
        width: 100%;
        height: 100%;
        background-color: rgba(224, 144, 135, 0.4);
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const PhotoSign = styled.p`
  color: ${({color}) => color || '#525252'};
  font-family: Lato;
  font-size: 0.65rem;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  text-align: center;
  margin-top: 1rem;
`;

export const TextSpaceMargin = styled.div`
  width: 80%;
  margin-right: auto;

  @media (max-width: 1365px) {
    width: 100%;
  }
`;

const animImg = keyframes`
  0%{
    transform: translateX(100%);
  }
  100%{
    transform: translateX(0);
  }
`;

export const ImageMove = styled.img`
  position: fixed;
  top: -20px;
  right: 0;
  max-width: 18rem;
  transform: translateX(100%);
  animation: ${animImg} 1.5s 1s forwards;

  @media (max-width: 1365px) {
    display: none;
  }
`;

export const GalleryAsyncGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  @media (max-width: 768px) {
    flex-direction: column;

    div {
      width: 100% !important;
    }
  }
`;
